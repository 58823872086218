.Button {
    background-color: white;
    display: inline-block;
    border: 1px solid black;
    padding: 10px;
    filter: drop-shadow(15px 15px 0px black);
    cursor: pointer;
    margin-right: 25px;
    user-select: none;
    pointer-events: all;
    font-family: "Major Mono Display", monospace;
}

@media (max-width: 500px) {
    .Button  {
        margin-bottom: 30px;
    }
}

.Button:hover {
    animation: 1s slidein;
    transform: translate(5px, 5px);
    filter: drop-shadow(10px 10px 0px black);
}

.Button:active {
    transform: translate(10px, 10px);
    filter: drop-shadow(5px 5px 0px black);
}
