.App {
    height: 100%;
    width: 100%;
    font-size: 50vh;
    touch-action: none;
}

.buttonGroup {
    font-size: 16px;
    flex-grow: 1;
}

@media (max-width: 500px) {
    .buttonGroup {
        display: flex;
        flex-direction: column;
    }
}

.bop {
    display: none;
    z-index: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    pointer-events: none;
    touch-actions: none;
    padding: 10px;
    height: calc(100% - 20px);
    justify-content: flex-end;
}

.bigName {
    flex-grow: 1;
    font-size: calc(80% - 30px);
    color: rgba(255,255,255,1);
    filter: drop-shadow(1px 1px 0 black);
    pointer-events: none;
    user-select: none;
    font-family: 'Abril Fatface', cursive;
    align-self: flex-end;
}

.panel {
    line-height: 24px;
    font-size: 14px;
    border: 1px solid black;
    filter: drop-shadow(15px 15px 0px black);
    background-color: white;
    padding: 10px;
    width: 30%;
    font-family: "Open sans";
    pointer-events: all;
    margin-top: 30px;
    max-height: 320px;
    display: flex;
    flex-direction: column;
}

.closeButton {
    font-family: "Major Mono Display";
    text-decoration: none;
    height: 0;
    align-self: end;
    z-index: 1;
    text-align: right;
    width: 100%;
}

.closeButton a{
    text-decoration: none;
    color: black;
}

.buttonGroup a {
    color: black;
}

.name {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    text-align: right;
    margin: 30px;
    pointer-events: none;
    touch-action: none;
}

.nav {
    pointer-events: none;
    width: 100%;
    position: absolute;
    z-index: 2;
    margin: 10px;
    max-height: calc(100% - 40px);
    display: flex;
    flex-direction: column;
}

.panel-title {
    display: none;
    font-family: "Major Mono Display", monospace;
    font-size: 16px;
    margin-bottom: 20px;
}

.projects {
    overflow: scroll;
    height: 100%;
}

.project {
}

.project-title {
    font-weight: 700;
}

.project-title a {
    color: black;
}

.project-description {

}

.project-wrapper:not(:last-child) .project-border {
    border-bottom: 1px solid black;
    margin-bottom: 25px;
}
.project-border {
    margin: auto;
    width: 25px;
    padding-top: 25px;
}


@media (max-width: 500px) {
    .bigName {
        font-size: 60px;
    }

    .name {
        position: fixed;
        bottom: 0;
    }

    .panel {
        width: calc(100% - 60px);
        margin-top: 0px;
        max-height: 100%;
    }

    .nav {
        max-height: 50%;
    }

    .buttonGroup:not(.home) {
        display: none;
    }

    .panel-title {
        display: block;
    }
}
